<template>
  <div class="MBPay-cont" :class='$mq'>
    <h1>Мои платежи</h1>

    <div class="MBpay-block-head">
      <h3>Подписка <span>активна</span></h3>
      <h3>950&#8381;/месяц</h3>
      <button>Отписаться</button>
    </div>

    <div class="MBpay-table table-2col">
      <div class="MBpay-cell left">Следующая дата платежа:</div>
      <div class="MBpay-cell left">17 июля, 2021</div>
      <div class="MBpay-cell left">Оплата картой:</div>
      <div class="MBpay-cell left">**** 9073</div>
    </div>

    <h3>Неоплаченные счета</h3>
    <div class="MBpay-table table-5col collapse" :class='$mq'>
      <div style="order:0;" class="MBpay-cell bold left">Курс</div>
      <div style="order:1;" class="MBpay-cell left">Name of course very long name</div>
      <div style="order:0;" class="MBpay-cell bold">Номер</div>
      <div style="order:1;" class="MBpay-cell">689560</div>
      <div style="order:0;" class="MBpay-cell bold">Сумма</div>
      <div style="order:1;" class="MBpay-cell">3000&#8381;</div>
      <div style="order:0;" class="MBpay-cell bold">Оплатить до</div>
      <div style="order:1;" class="MBpay-cell">01.09.2021</div>
      <div style="order:0;" class="MBpay-cell bold"></div>
      <div style="order:1;" class="MBpay-cell"><button class="purple">Оплатить</button></div>

    </div>


    <h3>Купленные курсы</h3>
    <div class="MBpay-table table-6col">
      <div class="MBpay-cell bold left">Курс</div>
      <div class="MBpay-cell bold">Номер</div>
      <div class="MBpay-cell bold">Сумма</div>
      <div class="MBpay-cell bold">Куплен</div>
      <div class="MBpay-cell bold">Доступ до</div>
      <div class="MBpay-cell bold"></div>

      <div class="MBpay-cell left">Name of course very long name</div>
      <div class="MBpay-cell">689560</div>
      <div class="MBpay-cell">3000&#8381;</div>
      <div class="MBpay-cell">01.09.2021</div>
      <div class="MBpay-cell">01.09.2022</div>
      <div class="MBpay-cell"><button class="pink">Продлить</button></div>

      <div class="MBpay-cell left">Name of course very long name</div>
      <div class="MBpay-cell">689560</div>
      <div class="MBpay-cell">3000&#8381;</div>
      <div class="MBpay-cell">01.09.2021</div>
      <div class="MBpay-cell">01.09.2022</div>
      <div class="MBpay-cell"><button class="pink">Продлить</button></div>

    </div>
  </div>
</template>

<style scoped>
.MBPay-cont {
  max-width: 900px;
  margin: 0 auto;
  padding: 4em 2em 2em 2em;
  box-sizing: border-box;
}

h1 {
  font-size: 3em;
  line-height: 0.9em;
  margin-bottom: 1em;
}

h3 {
  font-family: "Montserrat", sans-serif;
  font-size: 1.3em;
  font-weight: 600;
  line-height: 1em;
}
  h3 span {
    color: rgb(182 68 76);
  }

  button {
    border: none;
    padding: 0em 1em;
    border-radius: 1em;
    font-size: 0.8em;
    font-weight: 600;
    color: #432831;
    height: 2em;
  }
    button.purple {
      background-color: #462530;
      color: #ebb4ad;
    }
    button.pink {
      background-color: #fcd9d3;
      color: #462530;
    }

.MBpay-block-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5em;
}

.MBpay-table {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  box-sizing: border-box;
  box-shadow: 0 0.3em 1em #00000018;
  padding: 1.7em 3em;
  border-radius: 0.5em;
  margin-bottom: 3em;
  margin-top: 1.5em;
  background-color: #4f99a3;
}
  .MBpay-table.halftablet.collapse .MBpay-cell{
    width: 50%;
    text-align: left;
  }

.MBpay-cell {
  box-sizing: border-box;
  flex-grow: 1;
  width: 100%;
  padding: 0.5em 0.5em;
  line-height: 1em;
  text-align: center;
  background-color: pink;
}
  .MBpay-cell.bold {
    font-weight: 600;
  }
  .MBpay-cell.left{
    text-align: left;
  }

.table-2col .MBpay-cell {
  width: 50%;
}
.table-5col .MBpay-cell {
  width: 20%;
}
.table-6col .MBpay-cell {
  width: 16.5%;
}
</style>